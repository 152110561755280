export const UsuarioI = [
 /*{
        id_usuario: 1,
        nombre:'Santiago',
        apellido:'Álvarez',
        genero:'Masculino',
        fecha_n: new Date('2004-03-14'),
        edad:16,
        avatar:null,
        usuario:'jucamo0713',
        contraseña:'Elefante51',
        correo:'santiagoa150@gmail.com',
        registro_sistema: true
    }*/
]